import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductos } from '../redux/actions';
import Carousel from 'react-bootstrap/Carousel';
import carrusel3 from "../assets/Mendoza3.jpg";
import { connect } from 'react-redux';

function DarkVariantExample({ idioma }) {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.Productos || []);

  useEffect(() => {
    dispatch(getProductos());
  }, [dispatch]);

  console.log(allProducts.experiecias);

  return (
    <div>
      {/* Links para Google Fonts */}
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img
            className="w-100"
            src={carrusel3}
            alt="First slide"
            width="100px" height="660px"
          />
          {allProducts.experiecias.map((product) => (
            <>
              <div class="card" key={product.id}>
                <Carousel.Caption className='text-white'>
                  <h1 className='fuente bg-success text-white bg-opacity-50'>
                    {idioma === 'es' && product.textocarruseles}
                    {idioma === 'en' && product.textocarruselin}
                    {idioma === 'por' && product.textocarruselpor}
                  </h1>
                </Carousel.Caption>
              </div>
            </>
          ))}
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idioma: state.idioma,
});

export default connect(mapStateToProps)(DarkVariantExample);;



