import Spinner from 'react-bootstrap/Spinner';

function Loading() {
  return (
    <>
      
      <Spinner animation="grow" variant="success" />
      
    </>
  );
}

export default Loading;